import { CaretCircleRight, CheckCircle } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import Link from "next/link";
import PortalAnalytics from "PortalAnalytics";
import { URI_GET_STARTED } from "constants/urls";
import { ONBOARDING_CATEGORY_LIST } from "./tasks";
import { CompleteTask, OnboardingCategory, taskComplete } from "./types";

const CIRCLE_ICON_SIZE = 32;

type OnboardingCategoryListItemProps = {
    /** The category to pull details from, as well as a total task count and the id to use for the page redirect */
    category: OnboardingCategory;
    /** The number of completed sub-tasks, used to indicate progress and mark the category as done */
    tasksComplete: number;
};

/** A list item representing an OnboardingCategory, which shows its progress and can be clicked through to its page */
export const OnboardingCategoryListItem = ({
    category: { id: categoryId, title, description, icon, tasks },
    tasksComplete
}: OnboardingCategoryListItemProps): JSX.Element => {
    const isComplete = tasksComplete === tasks.length;
    const circleIcon = !isComplete ? (
        <CaretCircleRight
            className="[&>:not(rect)]:stroke-tertiaryOld"
            size={CIRCLE_ICON_SIZE}
        />
    ) : (
        <CheckCircle
            className="fill-success"
            size={CIRCLE_ICON_SIZE}
            weight="fill"
        />
    );
    return (
        <Link //TODO Confirm colors
            className="flex flex-col md:flex-row md:items-center gap-6 px-6 py-6 md:py-8 hover:bg-[#1414140f]"
            href={`${URI_GET_STARTED}/${categoryId}`}
            onClick={() =>
                PortalAnalytics.track("onboarding__open_task_list", {
                    category_id: categoryId
                })
            }
        >
            <div
                className={twMerge(
                    "rounded-lg bg-[#1414140f] p-3 w-fit",
                    isComplete && "[&>svg>path]:fill-secondaryDark"
                )}
            >
                {icon}
            </div>
            <div className="flex gap-x-6 flex-grow items-center">
                <div className="flex flex-col flex-grow gap-y-1">
                    <span
                        className={twMerge(
                            "font-semibold",
                            isComplete && "text-tertiary"
                        )}
                    >
                        {title}
                    </span>
                    {!isComplete && (
                        <span className="text-secondaryDark">
                            {description}
                        </span>
                    )}
                </div>
                <div className="flex flex-col md:flex-row gap-x-2 gap-y-1 items-center">
                    <span className="text-smallLH20 text-secondaryDark">
                        {tasksComplete}/{tasks.length}
                    </span>
                    {circleIcon}
                </div>
            </div>
        </Link>
    );
};

type OnboardingCategoryListProps = { completedTasks: CompleteTask[] };

/** A list of Onboarding task categories that can be clicked to navigate to their page */
export const OnboardingCategoryList = ({
    completedTasks
}: OnboardingCategoryListProps) => (
    <>
        {ONBOARDING_CATEGORY_LIST.map(category => (
            <OnboardingCategoryListItem
                key={category.id}
                category={category}
                tasksComplete={category.tasks.reduce(
                    (total, task) =>
                        total + (taskComplete(completedTasks, task) ? 1 : 0),
                    0
                )}
            />
        ))}
    </>
);
