import React from "react";
import { twMerge } from "tailwind-merge";
import { ModalFooterButtons, ModalProps, ModalSize } from "./modal-types";
import { ModalHeader } from "./ModalHeader";
import { ModalFooter } from "./ModalFooter";

export type { ModalSize, ModalFooterButtons, ModalProps };

const modalSizeStyles: Record<ModalSize, string> = {
    // Desktop: Capped width (376px); Mobile: Drawer on bottom
    small: "max-h-[90svh] tablet:max-w-[376px] left-0 right-0 bottom-0 rounded-t-xl",
    // Desktop: Capped width (472px); Mobile: Drawer on bottom
    medium: "max-h-[90svh] tablet:max-w-[472px] left-0 right-0 bottom-0 rounded-t-xl",
    // Desktop: Capped width (568px); Mobile: Fill screen
    large: "tablet:max-w-[568px] tablet:max-h-[90svh] inset-0",
    // Desktop: Fixed width/height (90% screen); Mobile: Fill screen
    xl: "tablet:w-[90vw] tablet:h-[90svh] inset-0"
};

export const Modal = ({
    size = "medium",
    footerButtons,
    className,
    overlayClassName,
    children,
    ...headerProps
}: ModalProps) => (
    <div
        className={twMerge(
            "fixed flex flex-col w-full h-full z-50 left-0 top-0 overflow-auto bg-charcoal/70 tablet:p-8 overscroll-none",
            overlayClassName
        )}
        onClick={headerProps.onClose}
    >
        <div
            className={twMerge(
                "fixed tablet:relative flex flex-col shadow bg-white m-auto tablet:rounded-xl",
                modalSizeStyles[size],
                className
            )}
            onClick={e => e.stopPropagation()}
        >
            <ModalHeader {...headerProps} />
            <div
                className={twMerge(
                    "p-6 pb-4 overflow-y-auto",
                    (size === "large" || size === "xl") && "flex-grow",
                    size === "large" && "tablet:flex-grow-0"
                )}
            >
                {children}
            </div>
            <ModalFooter footerButtons={footerButtons} />
        </div>
    </div>
);
