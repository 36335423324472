import { ForaLogo } from "components/common/v2/ForaLogo";

export const WelcomeBanner = (): JSX.Element => (
    <div className="w-full h-fit relative">
        {/* TODO: Consider serving banner through assets.fora.travel  */}
        <img
            src="/img/onboarding-banner.jpg"
            className="w-full h-[283px] object-cover"
        />
        <div className="absolute top-0 size-full grid place-content-center bg-black/15">
            <h1 className="flex gap-x-4 h-fit">
                <span className="text-title text-white font-semibold whitespace-nowrap">
                    Welcome to
                </span>
                <ForaLogo className="fill-white w-[102px]" />
            </h1>
        </div>
    </div>
);
