import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "../button/Button";
import { X } from "phosphor-react";
import { ModalProps } from "./modal-types";
import { IconButton } from "../IconButton";

type ModalHeaderProps = Pick<
    ModalProps,
    "title" | "subtitle" | "headerSize" | "headerAction" | "onClose"
>;

/** The header of a Modal, with an X button, and/or a title/action button (not for external use) */
export const ModalHeader = ({
    title,
    subtitle,
    headerSize,
    headerAction,
    onClose
}: ModalHeaderProps): JSX.Element | null => {
    const closeButton = onClose && (
        <IconButton
            Icon={X}
            variant="text"
            size="small"
            shape="square"
            onClick={onClose}
            testId="close-modal"
            aria-label="Close modal"
        />
    );

    let content: ReactNode = undefined;
    let headerSpacing: string | undefined = undefined;
    let hasBorder = false;

    if (headerAction) {
        content = <Button variant="text" size="small" {...headerAction} />;
        headerSpacing = "justify-between pb-4";
        hasBorder = true;
    } else if (title || (subtitle && headerSize !== "default")) {
        // If subtitle is present and headerSize is not defined, make it large to show the subtitle
        const largeHeader =
            headerSize === "large" || (!headerSize && !!subtitle);

        if (!largeHeader) {
            content = (
                <span
                    className={twMerge(
                        "flex-grow",
                        "h-8 content-center text-center",
                        onClose ? "mr-8" : "mx-8"
                    )}
                >
                    {title}
                </span>
            );
            headerSpacing = "pb-4";
        } else {
            content =
                title && subtitle ? (
                    <div className="flex flex-col gap-y-1 px-2.5">
                        <span className="text-header">{title}</span>
                        <span className="text-secondary">{subtitle}</span>
                    </div>
                ) : (
                    <span
                        className={`${
                            title ? "text-header" : "text-secondary"
                        } px-2.5`}
                    >
                        {title || subtitle}
                    </span>
                );
            headerSpacing = "flex-col gap-y-4";
        }

        hasBorder = !largeHeader;
    }

    return closeButton || content ? (
        <header
            className={twMerge(
                "flex px-3.5 pt-4",
                hasBorder && "border-b border-primary",
                headerSpacing
            )}
        >
            {closeButton}
            {content}
        </header>
    ) : null;
};
